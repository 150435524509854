import type { StrictImageProps } from 'semantic-ui-react';
import { Image } from 'semantic-ui-react';
import type { EAvatarSize } from 'typedefs/EAvatarSize';
import type { User } from 'typedefs/User';

/** Props for Avatar. */
type AvatarProps = JSX.IntrinsicElements['img'] &
	StrictImageProps & {
		/** The user for which to render the avatar. */
		user?: User;
		username: string;
		/** The size of the avatar. */
		avatarSize: EAvatarSize;
		/** If this is true, forces the use of Gravatar. */
		forceGravatar?: boolean;
	};

/** Component that shows the avatar image of the given user. */
export function Avatar({ user, username, avatarSize, forceGravatar, ...imgProps }: AvatarProps): JSX.Element {
	let src: string;
	const resultingUsername = (user?.username ?? username) || 'unknown';
	let altText = username;
	if (user) {
		altText = `${user.firstName} ${user.lastName}`;
	}
	if ((user && forceGravatar) || user?.useGravatar) {
		src = `https://secure.gravatar.com/avatar/${user.gravatarHash!}?size=${avatarSize.size}`;
	} else {
		src = `api/avatars/${encodeURIComponent(resultingUsername)}/image?size=${avatarSize.name}`;
	}
	return <Image avatar loading="lazy" src={src} alt={altText} {...imgProps} />;
}
